import React from 'react';
import { graphql } from 'gatsby';
import { get, last } from 'lodash-es';
import Layout from '../components/Layout/Layout';
import BlogList from '../components/BlogList';

export default function TagPage({ data, location }) {
  const path = get(location, 'pathname', '');
  const tagName = last(path.split('/'));
  const title = `blog | ${tagName}`;
  const edges = data.allMarkdownRemark.edges;
  return (
    <Layout title={title}>
      <BlogList data={edges} />
    </Layout>
  );
}
export const getPosts = graphql`
  query($tags_pattern: String!) {
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/content/posts/" }
        frontmatter: { tags: { regex: $tags_pattern } }
      }
    ) {
      edges {
        node {
          id
          frontmatter {
            date(formatString: "MMMM Do, YYYY")
            title
            tags
          }
          featuredImg {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          excerpt(pruneLength: 200)
          fields {
            slug
          }
        }
      }
    }
  }
`;
